/* label.checkbox {
  height: 1rem !important;
  line-height: 1.5 !important;
}

label.checkbox svg {
  width: 16px;
  height: 16px;
}

label.checkbox svg path:nth-of-type(2) {
  transform: translate(-2px, 1px);
}

label.checkbox svg > path:only-child {
  stroke: var(--neutral);
} */

.ant-checkbox {
  width: 14px;
  height: 14px;
  margin-top: -4px;
}

.ant-checkbox-inner::after {
  top: 44%;
  left: 16%;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-inner,
.ant-checkbox-checked:after,
.ant-checkbox-indeterminate .ant-checkbox-inner,
.ant-checkbox-checked .ant-checkbox-inner {
  border-color: #4cb75b;
  border-color: var(--primary);
}
.ant-checkbox-wrapper {
  color: inherit;
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #4cb75b;
  background-color: var(--primary);
}
