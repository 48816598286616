/* HTML INPUT INPUT */

.standard-input {
  box-shadow: none;
  font-size: 1.8em;
  padding-left: 0px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  outline: none;
  box-sizing: border-box;
  margin: 0;
  list-style: none;
  position: relative;
  display: inline-block;
  width: 100%;
  line-height: 1.5;
  background-image: none;
  border-radius: 4px;
  transition: all 0.3s;
  color: #4cb75b;
  color: var(--primary);
  @apply px-1;
}

input::-webkit-input-placeholder {
  color: #eaeaea;
  color: var(--neutral);
}

input.error {
  -webkit-box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}

/* ZEIT-UI INPUT */

.input-container .input-wrapper {
  border-style: none !important;
}

.input-container .input-wrapper input {
  font-size: 1.8em;
}

/* ANTD INPUT */
.ant-input-affix-wrapper {
  border-radius: 5px;
}

.ant-input,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: none !important;
}

.ant-input-affix-wrapper {
  @apply border-neutral;
  border-bottom-width: 2px;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):focus,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):active,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):focus-within {
  @apply border-primary;
  border-bottom-width: 1px;
}

.ant-input-affix-wrapper.border-2:focus,
.ant-input-affix-wrapper.border-2:focus-within,
.ant-input-affix-wrapper.border-2:hover {
  @apply border-r-2 !important;
}

/* .ant-input-affix-wrapper-focused.adaptive-focus {
  @apply mb-64;
} */

.text-field-input {
  min-height: calc(1.688 * 17pt);
}
