@tailwind base;

@layer base {
  html,
  body {
    padding: 0;
    margin: 0;
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
    color: rgba(16, 45, 61, 0.65);
  }

  * {
    box-sizing: border-box;
  }
  img,
  svg,
  video,
  canvas,
  audio,
  iframe,
  embed,
  object {
    vertical-align: unset;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: rgba(16, 45, 61, 0.85);
    margin: 0px 0px 0.625rem;
  }

  h1 {
    font-size: 2.5em;
    letter-spacing: -0.066875rem;
    line-height: 1.2;
    font-weight: 400;
  }

  h2 {
    font-size: 1.5em;
    letter-spacing: -0.020625rem;
    font-weight: 600;
  }

  h3 {
    font-size: 1.17em;
    letter-spacing: -0.029375rem;
    font-weight: 400;
  }
  h4 {
    font-size: 1.05em;
    letter-spacing: -0.020625rem;
    font-weight: 600;
  }
  h5 {
    font-size: 0.83em;
    letter-spacing: -0.01125rem;
    font-weight: 600;
  }
  h6 {
    font-size: 0.875em;
    letter-spacing: -0.005625rem;
    font-weight: 600;
  }

  p {
    margin-bottom: 0;
  }
  b {
    font-weight: 600;
  }

  small {
    line-height: 1.5;
    font-size: 0.875rem;
    margin: 0px;
  }
}

@tailwind components;
@tailwind utilities;

@import './antd.css';

@import './ui/checkbox.css';
@import './ui/skeleton.css';
@import './ui/fullpage.css';
@import './ui/input.css';
@import './ui/button.css';
@import './ui/modal.css';
@import './ui/select.css';

:root {
  --primary: #4cb75b;
  --neutral: #eaeaea;
  --vh: 100vh;
}

::selection {
  background-color: #4cb75b;
  background-color: var(--primary);
  color: white;
}

.link {
  color: #4cb75b;
  color: var(--primary);
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: border 0.2s ease;
  cursor: pointer;
}

.link:hover {
  border-bottom: 1px solid #4cb75b;
  border-bottom: 1px solid var(--primary);
}

.loading-container {
  min-width: 25px;
}

.intercom-lightweight-app {
  z-index: 999 !important;
}

.ant-notification-notice-message,
.ant-notification-notice-close {
  color: inherit;
}

.ant-notification-notice-close {
  @apply opacity-80;
}
.ant-notification-notice-close:hover {
  @apply opacity-100;
}

label.wrap-label,
.wrap-label > label {
  white-space: normal;
  height: auto;
}

.h-screen {
  height: 100vh !important;
  height: var(--vh) !important;
}

.min-h-screen {
  min-height: 100vh !important;
  min-height: var(--vh) !important;
}
.max-h-screen {
  max-height: 100vh !important;
  max-height: var(--vh) !important;
}

.center-xy,
.center-yx {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#fp-nav.fp-right {
  @apply right-1 md:right-4;
}
