.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select:not(.ant-select-disabled) .ant-select-selector,
.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  @apply border-primary;
}

.ant-select-focused.ant-select-multiple .ant-select-selector,
.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: 0 0 0 2px var(--shadow) !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  @apply bg-shadow;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
  @apply text-primary;
}

.ant-select {
  font-size: 0.875rem;
}

.ant-select .ant-select-selector {
  @apply rounded-md;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  @apply rounded-md;
}

.ant-select.ant-select-show-arrow .ant-select-selector {
  @apply pr-6;
}
