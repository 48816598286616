.full-screen-modal {
  height: 100vh;
  width: 100vw;
}

.full-screen-modal .ant-modal {
  @apply h-full;
  @apply w-full;
  @apply top-0;
  @apply p-0;
}

.full-screen-modal .ant-modal-content,
.full-screen-modal .ant-modal-body {
  @apply h-full;
  @apply w-full;
}

.full-screen-modal .ant-modal-body {
  @apply p-12;
}
